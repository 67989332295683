import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import moment from "moment";

interface PersistedErrorState {
    error: string
    timestamp: number
}


interface ErrorSetterContext {
    errorList: PersistedErrorState[]
    reportError: (newError: string) => void
    isDefault: boolean
}

const ErrorContext = createContext<ErrorSetterContext>({
    errorList: [],
    reportError: (newError) => {},
    isDefault: true
});

const warning = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
         stroke="currentColor" className="size-4 text-yellow-500">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"/>
    </svg>
)

const arrowLeft = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="size-4">
        <path fillRule="evenodd"
              d="M9.78 4.22a.75.75 0 0 1 0 1.06L7.06 8l2.72 2.72a.75.75 0 1 1-1.06 1.06L5.47 8.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0Z"
              clipRule="evenodd"/>
    </svg>
)

const arrowRight = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="size-4">
        <path fillRule="evenodd"
              d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"/>
    </svg>
)


export function ErrorViewer() {
    const {errorList, reportError, isDefault} = useContext(ErrorContext)
    const [pastIndex, setPastIndex] = useState<number | null>(null)

    const errorToShow = pastIndex != null ? errorList[pastIndex] : null
    const lastError = errorList.length > 0 ? errorList[errorList.length - 1] : null
    const isActive = (errorToShow?.timestamp ?? 0) > Date.now() - 60000
    useEffect(() => {
        if (errorList.length > 0 && errorList[errorList.length - 1].timestamp > Date.now() - 60000) {
            setPastIndex(errorList.length - 1)
        }
    }, [errorList]);

    useEffect(() => {
        if (pastIndex != null) {
            const timeoutId = setTimeout(() => {
                setPastIndex(null)
            }, 15000)
            return () => {
                clearTimeout(timeoutId)
            }
        }
    }, [pastIndex]);

    const viewPrevious = useCallback(() => {
        setPastIndex(prev => {
            return (prev ?? errorList.length) - 1
        })
    }, [errorList])
    const hasPrevious = (pastIndex == null || pastIndex != 0) && errorList.length > 0

    const viewNext = useCallback(() => {
        setPastIndex(prev => {
            return Math.min((prev ?? 0) + 1, errorList.length - 1)
        })
    }, [errorList])
    const hasNext = pastIndex != null && pastIndex < errorList.length - 1

    if (isDefault) {
        return (
            <div
                className={`bg-red-300 text-red-900 px-4 border-2 rounded-lg flex-wrap transition duration-300`}>
                {"Wrap your app into an ErrorStateDomain"}
            </div>
        )

    }


    return (
        <div className={`py-2`} hidden={errorList.length == 0}>
            {errorToShow && (
                <div className="flex flex-col px-6 items-start">
                    <div>
                        {moment(errorToShow.timestamp).fromNow()}:
                    </div>
                    <div
                        className={`${isActive ? "bg-red-300" : "bg-neutral-400"} ${isActive? "text-red-900" : "text-black"} px-2 border rounded-lg flex-wrap transition duration-500`}>
                        {errorToShow.error}
                    </div>
                </div>

            )}
            {
                (lastError != null && pastIndex == null) &&
                <button data-testid="error-warn" className="flex flex-row px-6 space-x-2 items-center" onClick={viewPrevious}>
                    <span>{warning}</span>
                    <span>no issue since {moment(lastError?.timestamp).fromNow(true)}</span>
                </button>
            }
            <div className={`flex flex-row px-6 items-center space-x-2 justify-between`}>
                <span hidden={pastIndex == null}>{pastIndex == errorList.length-1 ? "": `${errorList.length - (pastIndex ?? 0) -1} but `} last issue</span>
                <div className="flex flex-row space-x-2 justify-end">
                    <button data-testid="error-nav-prev" className="" disabled={!hasPrevious} onClick={viewPrevious} hidden={pastIndex == null}>{arrowLeft}</button>
                    <button data-testid="error-nav-next" className="" disabled={!hasNext} onClick={viewNext} hidden={pastIndex == null}>{arrowRight}</button>
                </div>
            </div>
        </div>
    )
}


interface ErrorStateDomainProps {
    children: ReactNode
}

function evictOldErrors(errorList: PersistedErrorState[]) {
    const evictedErrorList = errorList.filter((error) => error.timestamp > Date.now() - 1000 * 3600 * 2)
    if (evictedErrorList.length < errorList.length) {
        localStorage.setItem("reported_errors", JSON.stringify(evictedErrorList))
    }
    return evictedErrorList;
}

export function ErrorStateDomain({children}: ErrorStateDomainProps) {
    const [initialized, setInitialized] = useState(false)
    const [errorList, setErrorList] = useState<PersistedErrorState[]>([])

    if(!initialized) {
        const errors = localStorage.getItem("reported_errors")
        const errorList: PersistedErrorState[] = errors != null ? JSON.parse(errors) : []
        const evictedErrorList = evictOldErrors(errorList);
        setErrorList(evictedErrorList)
        setInitialized(true)
    }

    const handleNewError = useCallback((error: string) => {
        setErrorList((current) => {
            const newErrorList = [...current, {
                error,
                timestamp: Date.now()
            }]
            const evictedErrorList = evictOldErrors(newErrorList)
            localStorage.setItem("reported_errors", JSON.stringify(evictedErrorList))
            return newErrorList
        })
    }, [])

    return (
        <ErrorContext.Provider
            value={
                {
                    errorList: errorList,
                    reportError: handleNewError,
                    isDefault: false
                }
            }>
            {children}
        </ErrorContext.Provider>
    )
}

export const useErrorReporter = () => useContext(ErrorContext).reportError